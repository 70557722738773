import * as React from "react"
import PropTypes from "prop-types"
import "./aboutUs.css"
import SectionHeading from "./SectionHeading"

const AboutUs = ({ paragraphOne, paragraphTwo, paragraphThree, title, invertedColour }) => (
  <section className={`container ${invertedColour ? "inverted-colour" : null}`}>
    <SectionHeading title={title} invertedColour={invertedColour} />
    <p>{paragraphOne}</p>
    <p>{paragraphTwo}</p>
    <p>{paragraphThree}</p>
  </section>
)

AboutUs.propTypes = {
  paragraphOne: PropTypes.string,
  paragraphTwo: PropTypes.string,
  paragraphThree: PropTypes.string,
}

AboutUs.defaultProps = {
  paragraphOne: ``,
  paragraphTwo: ``,
  paragraphThree: ``,
}

export default AboutUs