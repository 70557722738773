import * as React from "react"
import PropTypes from "prop-types"
import "./whatWeDo.css"
import SectionHeading from "./SectionHeading"

const buildServicesList = (services) => {
  return services.map((service) => {
    return <li>{service}</li>
  })
} 

const WhatWeDo = ({ services, title }) => (
  <section className="container">
    <SectionHeading title={title}/>
    <ul className="services-list">
      {buildServicesList(services)}
    </ul>
  </section>
)

WhatWeDo.propTypes = {
  services: PropTypes.array,
}

WhatWeDo.defaultProps = {
  services: [],
}

export default WhatWeDo