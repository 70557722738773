import * as React from "react"
import PropTypes from "prop-types"
import "./sectionHeading.css"

const SectionHeading = ({ title, invertedColour }) => (
  <h2 className={`home-heading ${invertedColour ? "inverted-colour" : null }`}>{title}</h2>
)

SectionHeading.propTypes = {
  title: PropTypes.string,
  invertedColour: PropTypes.bool,
}

SectionHeading.defaultProps = {
  title: "About Us",
}

export default SectionHeading