import * as React from "react"
import PropTypes from "prop-types"
import "./theTeam.css"
import SectionHeading from "./SectionHeading"

const TheTeam = ({ paragraphOne, paragraphTwo, title, invertedColour }) => (
  <section className={`container ${invertedColour ? "inverted-colour-30" : null}`}>
    <SectionHeading title={title} invertedColour={invertedColour}/>
    <p>{paragraphOne}</p>
    <p>{paragraphTwo}</p>
  </section>
)

TheTeam.propTypes = {
  paragraphOne: PropTypes.string,
  paragraphTwo: PropTypes.string,
}

TheTeam.defaultProps = {
  paragraphOne: ``,
  paragraphTwo: ``,
}

export default TheTeam