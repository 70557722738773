import * as React from "react"
import PropTypes from "prop-types"
import "./projects-homepage.css"
import SectionHeading from "./SectionHeading"
import buildList from "../utils/buildList"

const Projects = ({ projects, title }) => (
  <section className="container">
    <SectionHeading title={title}/>
    <ul className="projects-list">
      {buildList(projects)}
    </ul>
    
  </section>
)

Projects.propTypes = {
  projects: PropTypes.array,
}

Projects.defaultProps = {
  projects: [],
}

export default Projects