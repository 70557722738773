import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import * as copy from "../copy/copy.json"
import AboutUs from "../components/AboutUs"
import WhatWeDo from "../components/WhatWeDo"
import TheTeam from "../components/TheTeam"
import Projects from "../components/ProjectsHomepage"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <AboutUs title={"About us"} paragraphOne={copy.about.paragraphOne} paragraphTwo={copy.about.paragraphTwo} paragraphThree={copy.about.paragraphThree} invertedColour={true}/>
    <WhatWeDo title={"What we do"} services={copy.services}/>
    <TheTeam title={"The team"} paragraphOne={copy.team.paragraphOne} paragraphTwo={copy.team.paragraphTwo} invertedColour={true}/>
    <Projects title={"Projects"} projects={copy.projects}/>
    <div class="gallery-link-container">
      <Link 
        style={{
          color: `#35662A;`,
          textDecoration: `none`,
          border: `1px solid #35662A`,
          padding: `.5rem`,
          fontSize: `large`,
          margin: `.5rem`,
        }}
        to="/projects">View project gallery
      </Link>
    </div>
  </Layout>
)

export default IndexPage
